<template>
  <div class="object" :class="type">
    <div class="image" :style="{ width, height }">
      <img :src="img" alt />
      <div class="category" v-if="type === 'icon'">
        <div class="icon" v-if="icon">
          <img :src="icon" />
        </div>
      </div>
      <div
        v-if="item.cost" 
        class="cost"
      >
        {{ item.cost }}
      </div>
      <div class="info">
        <div
          v-if="item.duration" 
          class="duration"
        >
          <clock-icon />
          <span class="text">{{ item.duration }}</span>
        </div>
        <div
          v-if="item.subtype"
          class="subtype"
        >
          <img
            :src="item.subtype.image"
            :style="{
              color: item.subtype.icon_settings.color,
              width: item.subtype.icon_settings.width + 'px',
              height: item.subtype.icon_settings.height + 'px'
            }"
            class="subtype__img"  
          >
          <span class="subtype__text">
            {{ item.subtype.title }}
          </span>
        </div>
      </div>
    </div>
    <div class="title" v-if="title">
      <span
        v-if="item.type"
        class="title__type-name"
      >
        {{ item.type.name }}
      </span>
      <span 
        v-if="item.type === 'excursion'"
        class="title__category"
      >
        Экскурсия
      </span>
      <span
        v-if="item.type === 'route'"
        class="title__category"
      >
        Маршрут
      </span>
      <span>{{ title }}</span>
    </div>
    <div class="category" v-if="category && type!=='icon'">
      <span>{{ category }}</span>
    </div>
  </div>
</template>

<script>
import ClockIcon from "@/components/Icons/Clock";

export default {
  name: "ObjectCard",

  components: {
    ClockIcon
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    type: {
      // icon
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    category: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "300px"
    },
    height: {
      type: String,
      default: "200px"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.object {
  .image {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background: $color_grey_3;
    transition: all 0.3s ease-in;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.9;
    }

    .category {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-top: 16px;

      .icon {

        img {
          width: 40px;
          height: 40px;
          border: 3px solid #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .cost {
      position: absolute;
      top: 16px;
      right: 16px;
      padding: 8px 12px;
      font-size: 23px;
      font-style: normal;
      line-height: 24px;
      background: rgba(30, 30, 30, 0.7);
      border-radius: 20px;
    }

    .info {
      position: absolute;
      bottom: 16px;
      left: 16px;
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 24px;
      color: #fff;

      .duration {
        display: flex;
        align-items: center;

        .text {
          margin-left: 12px;
        }
      }

      .subtype {
        display: flex;
        align-items: center;
        margin-left: 22px;

        &__img {
          height: 24px;
          width: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  &:active .image {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }

  .title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: -0.83px;
    color: rgba(#fff, 0.8);
    margin-top: 12px;

    &__category {
      display: block;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 16px;
      color: $color_grey_a6;
      text-transform: uppercase;
      margin-top: 16px;
    }

    &__type-name {
      display: block;
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 24px;
      opacity: 0.7;
      color: #fff;
      text-transform: uppercase;
    }
  }

  &.icon .title {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: -1px;
    color: rgba(#fff, 0.97);
  }
}
</style>