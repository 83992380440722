<template>
  <div class="tag" :class="{ 'active': active }">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.tag {
  background: $color_grey_3;
  padding: 24px 32px;
  border-radius: 14px;

  span {
    font-family: $ObjectSans;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.4px;
    color: rgba(#fff, 0.97);
  }

  &.active {
    background: $color_blue_2;
    span {
      color: $color_dark;
    }
  }
}
</style>

<style lang="scss">
.tags {
  .swiper-slide {
    width: auto;
    padding: 32px 0;
    margin-right: 16px;

    &:first-child {
      margin-left: 32px;
    }

    &:last-child {
      margin-right: 32px;
    }
  }
}
</style>